.container
    &.account
        .main
            min-height: calc(100vh - 205px)
            background: linear-gradient(98.852deg,$bg_light_orange 0 56%, $font_white 56% 100%)
            display: flex
            align-items: center
            .detail
                display: flex
                justify-content: center
                flex-direction: row
                align-items: center
                flex-wrap: wrap
                -webkit-flex-wrap: wrap
                padding: 15px 20px
                .img_area
                    flex: 1
                    max-width: 932px
                    height: 100%
                    margin-right: 40px
                    img
                        width: 100%
                        height: auto

                .info
                    flex: 1
                    padding: 80px 20px
                    padding-top: 60px
                    max-width: 370px
                    .buttons
                        .icon_button
                            border: 2px solid $border_dark_orange
                            border-radius: 20px
                            text-align: center
                            &:not(:last-child)
                                margin-bottom: 24px

                            p
                                max-width: 323px
                                padding: 20px 10px
                                +font($border_dark_orange, $font_s, 500, 56px)

                                &:before
                                    content: ""
                                    display: inline-block
                                    height: 56px
                                    width: 56px
                                    margin-right: 30px
                                    vertical-align: top
                            &.mobile
                                p
                                    &:before
                                        background: url("../assets/img/mobile_d.svg") center no-repeat
                                        background-size: contain
                            &.broadband
                                p
                                    &:before
                                        background: url("../assets/img/broadband_d.svg") center no-repeat
                                        background-size: contain
                            &:hover
                                background: $bg_dark_orange
                                p
                                    color: $font_white
                                &.mobile
                                    p
                                        &:before
                                            background: url("../assets/img/mobile_w.svg") center no-repeat
                                            background-size: contain
                                &.broadband
                                    p
                                        &:before
                                            background: url("../assets/img/broadband_w.svg") center no-repeat
                                            background-size: contain



+width-1000
    .container
        &.account
            .main
                background: linear-gradient(162.876deg,$bg_light_orange 0 35%, $bg_white 35% 100%)
                .detail
                    flex-direction: column
                    .img_area
                        margin-right: 0
                        max-width: 430px
                    .info
                        padding-top: 30px
                        .block_content
                            margin-bottom: 25px
+width-400
    .container
        &.account
            .main
                background: linear-gradient(162.876deg,$bg_light_orange 0 32%, $bg_white 32% 100%)
                .detail
                    .info
                        .buttons
                            .icon_button
                                p
                                    line-height: 50px
                                    &:before
                                        height: 50px
                                        width: 50px
                                        margin-right: 25px
