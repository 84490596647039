.container
    &.index
        & > div
            .block_title
                margin-bottom: 40px
        .banner
            background: $bg_white

            &:after
                content: ''
                position: absolute
                top: 0
                left: 0
                height: 0
                width: 0
                border-style: solid
                border-color: $bg_blue transparent transparent transparent
                border-width: 300px calc((1500px * 200 / 1440 + (-750px)) + 50vw) 0 0


            &:before
                content: ''
                position: absolute
                top: 0
                right: 0
                height: 0
                width: 0
                border-style: solid
                border-width: 200px 0 0 calc((1500px * 1240 / 1440 + (-750px)) + 50vw)
                border-color: $bg_black transparent transparent transparent

            .detail
                height: 100%
                max-width: 1270px
                padding: 30px 80px
                padding-top: 50px
                z-index: 10

                .info
                    display: inline-block
                    width: calc(100% + (-306px))
                    top: 50%
                    +transform(translateY(-50%))

                    .title
                        color: $font_black
                        font-weight: 400
                        font-size: $font_xl
                        margin-bottom: 10px
                        line-height: 24px

                        sup
                            top: -.75em
                            font-size: 50%

                    .sub_title
                        font-size: $font_xs
                        margin-bottom: 30px
                        line-height: 24px
                        color: $font_black
                        font-weight: 400

                    .buttons
                        .button
                            display: inline-block
                            margin-right: 20px

                            &:last-child
                                margin-right: 0

                .img
                    height: 100%
                    width: 300px
                    display: inline-block
                    text-align: center
                    top: 50%
                    +transform(translateY(-50%))

                    img
                        max-height: 100%
                        max-width: 100%
                        top: 50%
                        +transform(translateY(-50%))

        .feature
            background: $bg_white

            .detail
                padding: 60px 20px
                text-align: center

                .each
                    display: inline-block
                    width: calc(50% + (-30px))
                    margin-right: 50px
                    max-width: 400px
                    margin-bottom: 30px
                    padding: 5px 0

                    &:not(:first-of-type):nth-child(2n)
                        margin-right: 0

                    &:last-child, &:nth-last-child(2)
                        margin-bottom: 0

                    & > div
                        display: inline-block
                        vertical-align: middle

                    .img_area
                        width: 80px
                        height: 80px
                        margin-right: 5px
                        padding: 10px 0

                        img
                            max-width: 100%
                            max-height: 100%
                            top: 50%
                            +transform(translateY(-50%))

                    .text_area
                        width: calc(100% + (-110px))
                        text-align: left

                        .title
                            +font($font_black, $font_s, 400, 25px)
                            margin-bottom: 5px

                        .content
                            +font($font_gray, $font_xs, 400, 25px)


        .meta_info
            background: $gray
            .detail
                padding-top: 50px
                padding-bottom: 10px
                text-align: center
                padding-left: 20px
                padding-right: 20px

                .each
                    display: inline-block
                    width: calc(50% + (-30px))
                    margin-bottom: 35px

                    > div
                        display: inline-block
                        vertical-align: middle

                    .text_area
                        .title
                            +font( $black, $font_s, 400, 25px)
                            margin-bottom: 5px
                            text-align: center

                        .content
                            height: 50px
                            display: inline

                            .count
                                margin: 0px auto
                                text-align: center
                                +font( $orange,$font_xxxl, 400, 25px)


                            .plus
                                margin: 0px auto
                                vertical-align: 0px
                                +font( $orange,$font_xxl, 400, 25px)


                .description
                    color: $dark_gray
                    font-size: $font_b_1200
                    text-align: right
                    padding-right: 5%
                    vertical-align: baseline


        .suitable
            background: $gray

            .detail
                padding: 50px 20px
                text-align: center

                .each
                    display: inline-block
                    max-width: 850px
                    margin-bottom: 30px
                    padding: 5px 0
                    width: 100%

                    &:last-child
                        margin-bottom: 0

                    > div
                        display: inline-block
                        vertical-align: middle

                    .img_area
                        width: 80px
                        height: 80px
                        margin-right: 5px
                        padding: 10px 0

                        img
                            max-width: 100%
                            max-height: 100%
                            top: 50%
                            +transform(translateY(-50%))

                    .text_area
                        width: calc(100% + (-110px))
                        text-align: left

                        .title
                            margin-bottom: 5px
                            +font($black, $font_s, 400, 25px)

                        .content
                            +font($dark_gray, $font_xs, 400, 25px)
                            &>a
                                +font($orange, $font_xs, 400, 25px)


        .protect
            background: $white

            .detail
                padding: 60px 20px
                text-align: center

                .each
                    display: inline-block
                    max-width: 850px
                    padding: 5px 0
                    width: 100%

                    .content
                        color: $dark_gray
                        font-size: $font_xs
                        font-weight: 400
                        line-height: 25px
                        text-align: left

                    .img_area
                        padding: 10px 0

                        img
                            max-width: 100%
                            max-height: 100%

        .expert
            background: $gray

            .detail
                padding: 60px 20px
                text-align: center

                .each
                    display: inline-block
                    max-width: 850px
                    padding: 5px 0
                    width: 100%

                    .content
                        color: $dark_gray
                        font-size: $font_xs
                        font-weight: 400
                        line-height: 25px
                        text-align: left

                        a
                            line-height: 25px
                            color: $dark_orange


        .programs
            background: $bg_white
            .detail
                padding: 60px 20px
                .plan
                    .panel_content
                        .tab_pane
                            +width-1000
                                max-width: calc(100% + (-64px))
                                margin: 0 auto

        .videos
            background: $bg_gray
            .detail
                padding: 70px 20px
                padding-bottom: 100px
                .video_area
                    max-width: 850px
                    margin: 0 auto
                .video
                    margin: 0 auto
                    text-align: center
                    +width-1000
                        max-width: calc(100% + (-64px))

        .news
            background: $bg_white
            .detail
                padding: 70px 20px
                padding-bottom: 60px

                .table
                    max-width: 850px
                    margin: 0 auto

                    .row
                        border-bottom: 1px solid $gray_light
                        padding: 6px 5px
                        cursor: pointer

                        &:nth-last-child(2)
                            border-bottom: 0

                        .col
                            display: inline-block
                            line-height: 25px
                            font-size: $font_xs
                            font-weight: 400
                            padding: 0 5px
                            text-align: center
                            cursor: pointer
                            vertical-align: top

                            &.type
                                color: $font_orange
                                width: 76px

                            &.date
                                color: $font_gray
                                width: 100px

                            &.content
                                color: $font_black
                                width: calc(100% + (-185px))
                                text-align: left
                                letter-spacing: 1px

                                > p
                                    letter-spacing: 1px
                                    font-size: $font_xs

                                    > a
                                        letter-spacing: 1px
                                        font-size: $font_xs
                                >a
                                    line-height: 25px


                        &:hover
                            background: $white

                    .no_result
                        +font($font_black, $font_xs, normal, 100px)
                        text-align: center
                        border-top: 2px solid $border_gray
                        border-bottom: 2px solid $border_gray

                    .pagination
                        text-align: center
                        margin-top: 20px

                        li
                            height: 26px
                            min-width: 26px
                            display: inline-block
                            cursor: pointer
                            background-repeat: no-repeat
                            background-position: center

                            &:not(:last-of-type)
                                margin-right: 8px

                            a
                                +font($font_black, $font_xxs, normal, 26px)
                                display: block

                            &.now
                                border-bottom: 1px solid $border_orange

                                a
                                    color: $font_orange

                            &:hover
                                &:not(.disabled)
                                    a
                                        color: $font_orange

                            &.disabled
                                cursor: not-allowed

                            &.first
                                .img
                                    width: 26px
                                    height: 26px
                                    background: url("../assets/img/page_first.svg") right center no-repeat
                                    background-size: 10px

                                &.disabled
                                    .img
                                        cursor: not-allowed
                                        background: url("../assets/img/page_first_d.svg") right center no-repeat
                                        background-size: 10px

                                &:hover
                                    &:not(.disabled)
                                        .img
                                            background: url("../assets/img/page_first_w.svg") right center no-repeat
                                            background-size: 10px

                            &.pre
                                .img
                                    width: 26px
                                    height: 26px
                                    background: url("../assets/img/page_pre.svg") center no-repeat
                                    background-size: 10px

                                &.disabled
                                    .img
                                        cursor: not-allowed
                                        background: url("../assets/img/page_pre_d.svg") center no-repeat
                                        background-size: 10px

                                &:hover
                                    &:not(.disabled)
                                        .img
                                            background: url("../assets/img/page_pre_w.svg") center no-repeat
                                            background-size: 10px

                            &.next
                                .img
                                    width: 26px
                                    height: 26px
                                    background: url("../assets/img/page_next.svg") center no-repeat
                                    background-size: 10px

                                &.disabled
                                    .img
                                        cursor: not-allowed
                                        background: url("../assets/img/page_next_d.svg") center no-repeat
                                        background-size: 10px

                                &:hover
                                    &:not(.disabled)
                                        .img
                                            background: url("../assets/img/page_next_w.svg") center no-repeat
                                            background-size: 10px

                            &.last
                                .img
                                    width: 26px
                                    height: 26px
                                    background: url("../assets/img/page_last.svg") left center no-repeat
                                    background-size: 10px

                                &.disabled
                                    .img
                                        cursor: not-allowed
                                        background: url("../assets/img/page_last_d.svg") left center no-repeat
                                        background-size: 10px

                                &:hover
                                    &:not(.disabled)
                                        .img
                                            background: url("../assets/img/page_last_w.svg") left center no-repeat
                                            background-size: 10px

                            &:first-child
                                .img
                                    width: 26px
                                    height: 26px
                                    background: url("../assets/img/page_first.svg") right center no-repeat
                                    background-size: 10px

                                &.disabled
                                    .img
                                        cursor: not-allowed
                                        background: url("../assets/img/page_first_d.svg") right center no-repeat
                                        background-size: 10px

                                &:hover
                                    &:not(.disabled)
                                        .img
                                            background: url("../assets/img/page_first_w.svg") right center no-repeat
                                            background-size: 10px
                            &:last-child
                                .img
                                    width: 26px
                                    height: 26px
                                    background: url("../assets/img/page_last.svg") left center no-repeat
                                    background-size: 10px

                                &.disabled
                                    .img
                                        cursor: not-allowed
                                        background: url("../assets/img/page_last_d.svg") left center no-repeat
                                        background-size: 10px

                                &:hover
                                    &:not(.disabled)
                                        .img
                                            background: url("../assets/img/page_last_w.svg") left center no-repeat
                                            background-size: 10px
        .ad_area
            display: none

            .detail
                padding: 15px 0px

                .img
                    height: 90px
                    width: 768px
                    background: $bg_gray
                    margin: 0 auto


        & ~ #kumawin
            .dialog
                max-width: 700px

        +width-1500
            .banner
                &:after
                    content: ''
                    border-width: 300px calc(100vw * 200 / 1440) 0 0

                &:before
                    content: ''
                    border-width: 200px 0 0 calc(100vw * 1240 / 1440)

                .detail
                    padding: 30px 120px
                    padding-top: 50px
        +width-1400
            .banner
                .detail
                    padding: 30px 170px
                    padding-top: 50px
        +width-1200
            .banner
                .detail
                    .info
                        width: calc(100% + (-255px))

                    .img
                        width: 250px

                    .iframe
                        width: 250px
        +width-1024
            .banner
                &:after
                    content: ''
                    border-width: 300px calc(100vw * 300 / 1440) 0 0

                &:before
                    content: ''
                    border-width: 200px 0 0 calc(100vw * 1140 / 1440)

        +width-850
            .banner
                .detail
                    padding: 30px 130px
                    padding-top: 70px

                    .img
                        display: none
                    .info
                        display: block
                        width: 70%
            .feature
                .detail
                    .each
                        &:not(:first-of-type):nth-child(2n)
                            margin-right: auto
        +width-800
            .banner
                .detail
                    padding: 30px 170px
                    padding-top: 70px

                &:after
                    content: ''
                    border-width: 300px calc(100vw * 380 / 1440) 0 0

                &:before
                    content: ''
                    border-width: 200px 0 0 calc(100vw * 1060 / 1440)

            .meta_info .detail .each
                display: inline-block
                width: calc(50% + (-80px))
                .text_area .content
                    text-align: center
                    font-size: $font_xxl
                    width: 100%
                    .count
                        font-size: $font_xxl
                        margin-right: 0px
                    .plus
                        font-size: $font_ll
                        vertical-align: 5px
                        margin-left: -10px
        +width-700
            .banner
                .detail
                    padding: 30px 130px
                    padding-top: 70px

                    .info
                        width: 100%

                &:after
                    content: ''
                    border-width: 240px calc(100vw * 380 / 1440) 0 0

                &:before
                    content: ''
                    border-width: 160px 0 0 calc(100vw * 1060 / 1440)
            .feature
                .detail
                    .each
                        .img_area
                            display: block
                            margin: 0 auto

                        .text_area
                            display: block
                            width: 100%

                            .title
                                text-align: center
            .meta_info .detail
                display: block
                padding-top: 45px
                margin: 0 auto

                .each
                    display: block
                    width: 100%
                    margin-bottom: 45px

                    .text_area
                        .title
                            text-align: center

                        .content
                            text-align: center
                            width: 100%

                            .count
                                font-size: $font_xll
                                margin-right: 0px

                            .plus
                                font-size: $font_ll
                                vertical-align: 5px
                                margin-left: -8px

                        #meta_info_count_2
                            text-align: center

                .description
                    font-size: $font_xs
                    margin-top: 30px

        +width-600
            .banner
                .detail
                    padding: 30px 130px
                    padding-top: 70px

                &:after
                    content: ''
                    border-width: 240px calc(100vw * 380 / 1440) 0 0

                &:before
                    content: ''
                    border-width: 160px 0 0 calc(100vw * 1060 / 1440)
        +width-500
            .banner
                .detail
                    padding: 30px 60px
                    padding-top: 70px

                &:after
                    content: ''
                    border-width: 120px calc(100vw * 380 / 1440) 0 0

                &:before
                    content: ''
                    border-width: 80px 0 0 calc(100vw * 1060 / 1440)
            .feature
                .detail
                    .each
                        display: block
                        margin: 0 auto
                        width: 100%
                        max-width: 240px

                        &:nth-child(2n)
                            margin-right: auto
                        &:empty
                            display: none
            .news
                .detail
                    .table
                        max-width: 370px

                        .row
                            .col
                                display: block

                                &.type
                                    text-align: left

                                &.date
                                    text-align: left

                                &.content
                                    width: 100%
        +width-400
            .banner
                .detail
                    padding: 30px 35px
                    padding-top: 80px


