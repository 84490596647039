.win
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 100
    display: none
    background: rgba(0, 0, 0, 0.6)
    cursor: pointer
    height: 100vh
    width: 100%
    padding: 0 10px
    .dialog
        position: relative
        margin: 0 auto
        z-index: 10
        height: auto
        max-height: calc(100vh + (-30px))
        width: 100%
        max-width:  850px
        border-radius: 2px
        cursor: pointer
        top: 50%
        +transform(translateY(-50%))
        overflow-y: auto
        .content
            background: $bg_white
            text-align: center
            padding: 40px 60px
            .cross
                position: absolute
                display: block
                height: 30px
                width: 30px
                background: url("../assets/img/cross.svg") no-repeat center
                background-size: 12px
                right: 10px
                top: 10px
                cursor: pointer
            .win_header
                margin-bottom: 10px
                text-align: left
                p
                    +font($font_black, $font_m, 500, 40px)
            .win_body
                .time
                    +font($font_orange, $font_xs, normal, 30px)
                p
                    text-align: left
                    margin-bottom: 5px
                    +font($font_black, $font_xs, normal, 30px)
                    &.note
                        +font($font_orange, $font_xxs, normal, 25px)
                    a
                        +font($font_orange, $font_xs, normal, 30px)
                        word-break: break-all
                ol
                    padding-left: 1.5rem
                    li
                        list-style-type: decimal
                        text-align: left
                        +font($font_black, $font_xs, 400, 30px)
                        a
                            +font($font_orange, $font_xs, 400, 30px)
                ul
                    padding-left: 1.5rem
                    li
                        list-style-type: disc
                        text-align: left
                        +font($font_black, $font_xs, 400, 30px)
                        a
                            +font($font_orange, $font_xs, 400, 30px)

            .win_footer
                    margin-top: 15px
                    .buttons
                        input
                            border-radius: 2px
                            height: 30px
                            width: auto
                            min-width: 100px
                            padding: 0 20px
                            text-align: center
                        .confirm
                            background: $bg_orange
                            border: 1px solid $border_orange
                            +font($font_white, $font_xxs, normal, 28px)
                            cursor: pointer
                            &:hover
                                background: darken($bg_orange, 10%)
                                border: 1px solid darken($border_orange, 10%)
                            &:disabled
                                background-color: #ccc
                                cursor: not-allowed
                                opacity: 0.5

                        .cancel
                            background: $bg_gray
                            border: 1px solid $border_gray_light
                            +font($font_gray, $font_xxs, normal, 28px)
                            cursor: pointer
                            &:hover
                                background: $bg_gray_dark
                                color: $font_white
                                border: 1px solid $border_gray_light



            +width-600
                padding: 40px
            +width-400
                padding: 40px 20px
                .win_header
                    p
                        line-height: 35px
    &.rent
        .win_header
            p
                text-align: center
                margin: 0
            margin-bottom: 0!important

        .win_body
            padding: 0 10px
            margin-top: 0!important
            height: calc(100% + (-235px))
            max-height: 350px
            #eula_title
                display: block
                text-align: center
                margin-bottom: 30px
                +font($font_black, $font_s, 500, 25px)

