.splide
    padding: 0
    &:not( .is-overflow )
        .splide__list
            justify-content: center
        .splide__slide
            &:last-child
                margin: 0 !important

    &.video-splide
        .splide__pagination
            bottom: -31px
            li
                &:not(:last-child)
                    margin-right: 7px
                .splide__pagination__page
                    background: $bg_white
                    opacity: 1
                    height: 9px
                    width: 9px
                    margin: 0
                    &.is-active
                        background: $bg_orange
                        +transform(scale(1))
        .splide__track
            .splide__list
                .splide__slide
                    background-size: 100% 100% !important
                    &.is-next
                        z-index: -1
                        background-size: 90% 90% !important
                        pointer-events: none
                        filter: grayscale(0.5)
                        transform: translateX(-82%)
                        .splide__video
                            +transform(scale(0.9))

                    &.is-prev
                        z-index: -1
                        background-size: 90% 90% !important
                        pointer-events: none
                        filter: grayscale(0.5)
                        transform: translateX(82%)
                        .splide__video
                            +transform(scale(0.9))
                    .splide__video
                        .splide__video__play
                            height: 129px
                            width: 129px
                            background: url("../assets/img/video_player.svg") center no-repeat
                            background-size: contain
                            +width-800
                                height: 90px
                                width: 90px
                            +width-600
                                height: 50px
                                width: 50px
                            +width-400
                                height: 30px
                                width: 30px
                            &:after
                                display: none
        .splide__arrows
            position: initial
            .ctg-video-splide-arrows
                background: transparent
                height: 32px
                width: 32px
                opacity: 1
                &.splide__arrow
                    border: none !important
                    &:hover
                        svg
                            path
                                fill: $video_dark_gray
                    &:focus-visible
                        border: none
                &:hover
                    &:not(:disabled)
                        opacity: 1
                        cursor: pointer
                &:disabled
                    opacity: 0
                &.splide__arrow--prev
                    left: -56px
                    cursor: pointer
                    *
                        cursor: pointer
                &.splide__arrow--next
                    right: -56px
                    cursor: pointer
                    *
                        cursor: pointer
                svg
                    height: 100%
                    width: 100%


        &:not( .is-overflow )
            .splide__arrows
                display: none

    &.splide-plans
        .splide__arrows
            position: initial
            .ctg-plan-splide-arrows
                background: $bg_orange
                border-radius: 50%
                height: 32px
                width: 32px
                opacity: 1
                &:hover
                    &:not(:disabled)
                        opacity: 1
                        cursor: pointer
                        background: $bg_dark_orange
                &:disabled
                    opacity: 0
                &.splide__arrow--prev
                    left: -56px
                    cursor: pointer
                    *
                        cursor: pointer
                &.splide__arrow--next
                    right: -56px
                    cursor: pointer
                    *
                        cursor: pointer
                svg
                    height: 100%
                    width: 100%


+width-1000
    .splide
        &.video-splide
            .splide__arrows
                .ctg-video-splide-arrows
                    &.splide__arrow--prev
                        left: -40px
                    &.splide__arrow--next
                        right: -40px
        &.splide-plans
            .splide__arrows
                .ctg-plan-splide-arrows
                    &.splide__arrow--prev
                        left: -40px
                    &.splide__arrow--next
                        right: -40px
