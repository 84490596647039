.container
    .logo
        margin: 0 auto
        margin-bottom: 5px
        height: 40px
        width: 150px
        background: url("../assets/img/hinet_logo.png") no-repeat center
        background-size: contain
    .footer
        background: linear-gradient(to bottom, $bg_blue 0%, $bg_black 90%)
        .detail
            padding: 30px
            .link
                text-align: center
                margin: 0 auto
                margin-bottom: 15px
                max-width: 500px
                & > div
                    margin-bottom: 5px
                    display: inline-block
                    border-right: 1px solid $border_white
                    padding: 0 8px
                    a
                        +font($font_white, $font_xxs, 400, 20px)

                    &:hover
                        a
                            color: $font_light_gray
                    &:last-child
                        border-right: 0
                    &:nth-child(4)
                        border-right: 0
            .copyright
                +font($font_white, $font_xxs, 400, 20px)
                text-align: center

        +width-500
            .detail
                .link
                    max-width: 400px
                    & > div
                        &:nth-child(3)
                            border-right: 1px solid $border_white
                        &:nth-child(4)
                            border-right: 0
                        &:nth-child(8)
                            border-right: 0
        +width-410
            .detail
                .link
                    max-width: 260px
                    & > div
                        padding: 0 6px
                        &:nth-child(2)
                            border-right: 1px solid $border_white
                        &:nth-child(3)
                            border-right: 0
                        &:nth-child(4)
                            border-right: 0
                        &:nth-child(5)
                            border-right: 1px solid $border_white
                        &:nth-child(6)
                            border-right: 1px solid $border_white
                        &:nth-child(7)
                            border-right: 0
                        &:nth-child(8)
                            border-right: 1px solid $border_white
        +width-350
            .detail
                .link
                    max-width: 260px
                    & > div
                        padding: 0 6px
                        &:nth-child(2)
                            border-right: 0
                        &:nth-child(3)
                            border-right:  1px solid $border_white
                        &:nth-child(4)
                            border-right: 0
                        &:nth-child(5)
                            border-right: 1px solid $border_white
                        &:nth-child(6)
                            border-right: 0
                        &:nth-child(7)
                            border-right: 0
                        &:nth-child(8)
                            border-right: 1px solid $border_white

