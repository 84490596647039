$orange: #f3ae3a
$light_orange:#F9AE3B
$lighten_orange:#FFF6E6
$dark_orange: #F5911E
$darken_orange: #724417
$green: #0089a7
$dark_green: #005F74
$black: #12284b
$black_blue: #213865
$white: #fff
$light_gray: #EFF4F6
$gray_light: #bfc4ce
$gray: #f0f5f7
$dark_gray: #8892a4
$dark_gray_black: #4F5051
$blue: #344666
$red: #F77
$brown: #9d8189
$video_gray:#B6BABF
$video_dark_gray:#8F9399

$backend_red: #F77
$backend_dark_red: #f44
$backend_green: #69e0b5
$backend_dark_green: #69e0b5
$backend_blue: #a5d1f4
$backend_dark_blue: #78baee
$package_gray: #878787
$package_dark_gray: #6c6c6c


$font_b_1920: 16px
$font_b_1200: 14px


$font_xxxl: 3.375rem
//54
$font_xxl: 2.25rem
//36
$font_xll: 2.125rem
//34
$font_xl: 1.75rem
//28
$font_ll: 1.5rem
//24
$font_l: 1.375rem
//22
$font_m: 1.25rem
//20
$font_s: 1.125rem
//18
$font_xs: 1rem
//16
$font_xxs: 0.875rem
//14

$font_black: $black
$font_white: $white
$font_light_gray: $gray_light
$font_gray: $dark_gray
$font_orange: $orange
$font_dark_orange: $dark_orange
$font_darken_orange: $darken_orange
$font_green: $green
$font_dark_green: $dark_green
$font_red: $red
$font_brown: $brown
$font_blue: $blue

$bg_orange: $orange
$bg_dark_orange: $dark_orange
$bg_light_orange: $lighten_orange
$bg_green: $green
$bg_gray_light: $gray_light
$bg_gray: $gray
$bg_gray_dark: $dark_gray
$bg_white: $white
$bg_black: $black
$bg_red: $red
$bg_brown: $brown
$bg_blue: $blue

$border_black: $black
$border_orange: $orange
$border_dark_orange: $dark_orange
$border_green: $green
$border_gray: $dark_gray
$border_gray_light: $gray_light
$border_white: $white
$border_red: $red
$border_brown: $brown
$border_blue: $blue

$media-1500: 1500px
$media-1400: 1400px
$media-1200: 1200px
$media-1000: 1000px
$media-1050: 1050px
$media-1024: 1024px
$media-900: 900px
$media-850: 850px
$media-800: 800px
$media-780: 780px
$media-700: 700px
$media-650: 650px
$media-600: 600px
$media-540: 540px
$media-500: 500px
$media-410: 410px
$media-400: 400px
$media-350: 350px

@mixin width-1500()
    @media all and (max-width: $media-1500)
        @content

@mixin width-1400()
    @media all and (max-width: $media-1400)
        @content

@mixin width-1200()
    @media all and (max-width: $media-1200)
        @content

@mixin width-1050()
    @media all and (max-width: $media-1050)
        @content
@mixin width-1024()
    @media all and (max-width: $media-1024)
        @content

@mixin width-1000()
    @media all and (max-width: $media-1000)
        @content

@mixin width-900()
    @media all and (max-width: $media-900)
        @content

@mixin width-850()
    @media all and (max-width: $media-850)
        @content

@mixin width-800()
    @media all and (max-width: $media-800)
        @content

@mixin width-780()
    @media all and (max-width: $media-780)
        @content

@mixin width-700()
    @media all and (max-width: $media-700)
        @content

@mixin width-650()
    @media all and (max-width: $media-650)
        @content

@mixin width-600()
    @media all and (max-width: $media-600)
        @content

@mixin width-540()
    @media all and (max-width: $media-540)
        @content

@mixin width-500()
    @media all and (max-width: $media-500)
        @content
@mixin width-410()
    @media all and (max-width: $media-410)
        @content
@mixin width-400()
    @media all and (max-width: $media-400)
        @content
@mixin width-350()
    @media all and (max-width: $media-350)
        @content

@mixin box-sizing()
    box-sizing: border-box
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box

@mixin transform($value)
    -moz-transform: $value
    -o-transform: $value
    -ms-transform: $value
    -webkit-transform: $value
    transform: $value

@mixin font( $color, $size, $weight, $line-height: 1.2)
    color: $color
    font-size: $size
    font-weight: $weight
    line-height: $line-height

@mixin transition($value)
    -moz-transition: $value
    -o-transition: $value
    -ms-transition: $value
    -webkit-transition: $value
    transition: $value
