@import variable.sass
@import init.sass
@import win.sass
@import index.sass
@import nav.sass
@import footer.sass
@import knowledge.sass
@import program.sass
@import package_info.sass
@import report.sass
@import faq.sass
@import blocking_record.sass
@import account.sass
@import ctg_splide.sass
@import manager.sass

.button
    display: inline-block
    height: 30px
    min-width: 100px
    padding: 0 10px
    text-align: center
    border-radius: 2px
    cursor: pointer

    &.oval
        border-radius: 20px

    &.radius_s
        border-radius: 2px

    &.radius_m
        border-radius: 10px

    &.radius_l
        border-radius: 20px

    p
        +font($font_white, $font_xxs, normal, 28px)
        cursor: pointer

    &.orange
        background: $bg_orange
        border: 1px solid $border_orange
        +font($font_white, $font_xxs, normal, 28px)

        &:hover
            background: darken($bg_orange, 10%)
            border: 1px solid darken($border_orange, 10%)

    &.green
        background: $bg_green
        border: 1px solid $border_green
        color: $font_white
        +font($font_white, $font_xxs, normal, 28px)

        &:hover
            background: darken($bg_green, 10%)
            border: 1px solid darken($border_green, 10%)

    &.green_line
        background: transparent
        border: 1px solid $border_green

        p
            color: $font_green

        &:hover
            background: rgba($bg_green, 0.1)

    &.red
        background: $red
        border: 1px solid $border_red

        &:hover
            background: darken($bg_red, 10%)
            border: 1px solid darken($border_red, 10%)

    &.brown
        background: $brown
        border: 1px solid $border_brown

        &:hover
            background: darken($bg_brown, 10%)
            border: 1px solid darken($border_brown, 10%)

    &.button_l
        min-height: 60px
        padding: 5px 10px
        font-size: $font_s
        font-weight: 500
        line-height: 48px
        cursor: pointer

.index_button
    border-radius: 20px
    height: 30px
    text-align: center
    background: transparent
    border: 2px solid $border_orange
    overflow: hidden

    &:hover, &.active
        p
            color: $font_white
            background: $bg_orange

        &:after
            background: url("../assets/img/index_icon_h.svg") center no-repeat, $bg_white
            background-size: 14px auto

    &:not(:last-child)
        margin-right: 22px

    &:after
        content: ""
        display: inline-block
        height: 100%
        width: 30px
        background: url("../assets/img/index_icon.svg") center no-repeat, $bg_orange
        background-size: auto 16px
        border-radius: 20px
        margin-right: -2px

    p
        display: inline-block
        min-width: 100px
        border-radius: 20px
        +font($font_orange, $font_s, normal, 26px)
        background: $bg_white

.panel
    width: 100%

    .panel_tabs
        margin-bottom: 35px

        .item
            display: inline-block
            height: 30px
            min-width: 116px
            padding: 0 10px
            line-height: 26px
            text-align: center
            border-radius: 20px
            font-size: $font_xs
            font-weight: 500
            cursor: pointer
            border: 2px solid $border_orange
            color: $font_orange
            background: $bg_white

            &:not(:last-child)
                margin-right: 22px

            &.active
                background: $bg_orange
                color: $font_white

            &:hover
                &:not(.active)
                    background: $bg_orange
                    color: $font_white

                &.active
                    background: $bg_orange
                    color: $font_white

    .panel_content
        background: transparent
        border-radius: 4px

        .tab_pane
            display: none

            &.active
                display: block

.filter_button
    margin-bottom: 40px
    display: flex
    justify-content: center

    ul
        display: flex
        flex-wrap: wrap
        -webkit-flex-wrap: wrap
        justify-content: space-around
        flex-direction: row
        gap: 0 22px

        li
            display: inline-flex
            background: $bg_green

            color: $font_white

            &.active
                border-color: $border_orange
                background: $bg_orange

    .item
        +font($font_white, $font_s, 400, 26px)
        border: 2px solid $border_green
        border-radius: 25px
        min-height: 30px
        padding: 0 15px
        cursor: pointer
        align-items: center
        justify-content: center

        &.w-116
            width: 116px

        &.w-170
            width: 170px

        &:hover
            border-color: $border_orange
            background: $bg_orange

        &.current
            display: none
            border-color: $border_orange
            background: $bg_orange

            &:after
                margin-left: 10px
                content: ""
                display: inline-block
                height: 17px
                width: 17px
                background: url("../assets/img/filter_arrow.svg") center no-repeat
                background-size: 13px auto

    +width-800
        &.show
            .item
                &.current
                    display: none

            ul
                display: flex
        ul
            display: none
            flex-direction: column

            li
                display: flex
                flex-direction: row

                &:first-of-type
                    border-radius: 0
                    border-top-left-radius: 15px
                    border-top-right-radius: 15px

                &:not(:last-of-type):not(:first-of-type)
                    border-radius: 0

                &:last-of-type
                    border-radius: 0
                    border-bottom-left-radius: 15px
                    border-bottom-right-radius: 15px

                &:first-child
                    &:after
                        background: url("../assets/img/filter_arrow.svg") center no-repeat
                        background-size: 13px auto

                &:after
                    margin-left: 10px
                    content: ''
                    display: inline-block
                    height: 17px
                    width: 17px

        .item
            background: $bg_orange
            border-color: $border_orange
            justify-content: space-between

            &.current
                display: flex

            &:hover
                background: $bg_dark_orange
                border-color: $border_dark_orange

.button_group
    display: flex
    flex-direction: column
    align-items: center
    gap: 13px

    .button
        min-width: 150px

.cards
    max-width: 850px
    margin: 0 auto
    margin-bottom: 60px
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    gap: 25px 75px

    .card
        flex: 1
        background: $bg_light_orange
        border-radius: 20px
        padding: 20px 25px
        text-align: center
        min-width: 270px

        .title
            +font($font_darken_orange, $font_s, 500, 26px)

            &.size_small
                +font($font_darken_orange, $font_xs, 400, 24px)

        .content
            padding-top: 34px
            padding-bottom: 34px

            & > p
                +font($font_darken_orange, $font_xs, 400, 24px)

                &:not(:last-child)
                    margin-bottom: 23px

.container
    padding-top: 60px

    .main
        min-height: calc(100vh + (-205px))

    & > div
        width: 100%

        & > .detail
            max-width: 1270px
            width: 100%
            margin: 0 auto

        .block_title
            +font($font_black, $font_l, 500, 1.2)
            margin-bottom: 16px
            text-align: center

        .block_content
            max-width: 850px
            padding: 5px 0
            width: 100%
            margin: 0 auto
            margin-bottom: 60px

            p
                +font($font_gray, $font_xs, 400, 25px)
                text-align: left

                &:not(:last-child)
                    margin-bottom: 9px

                & > a
                    +font($font_orange, $font_xs, 400, 25px)

        &.banner
            height: 300px

        &.programs
            .detail
                padding: 70px 20px
                padding-bottom: 60px
                text-align: center

                .plans
                    margin-bottom: 60px

                    &.special_layout
                        .plan
                            max-width: 820px

                    &.broadband
                        .plans_title
                            &:after
                                background: url("../assets/img/broadband.svg") center no-repeat
                                background-size: contain

                    &.mobile
                        .plans_title
                            &:after
                                background: url("../assets/img/mobile.svg") center no-repeat
                                background-size: contain

                    .plans_title
                        +font($font_orange, $font_s, 500, 28px)
                        margin-bottom: 15px

                        &:after
                            content: ""
                            height: 28px
                            width: 22px
                            margin-left: 5px
                            display: inline-block
                            vertical-align: top

                    .plans_info
                        +font($font_gray, $font_xs, 400, 1.5)
                        text-align: center
                        margin-bottom: 20px

                    .plan
                        display: flex
                        flex-wrap: wrap
                        -webkit-flex-wrap: wrap
                        gap: 30px 40px
                        justify-content: center
                        align-content: flex-start

                .splide-plans
                    .splide__slide
                        .each
                            height: 100%
                            margin: 0 auto
                            width: 100%
                            max-width: 250px
                            min-width: 210px

                .plan
                    max-width: 850px
                    margin: 0 auto
                    text-align: center
                    justify-content: center

                    .each
                        background: $bg_white
                        border: 1px solid rgba($border_gray, 0.3)
                        padding: 10px
                        width: 250px
                        flex-grow: 0
                        flex-shrink: 0

                        &:nth-child(even)
                            & > div
                                border-image: linear-gradient(to right bottom, $border_white 30%, $border_orange 100%)
                                border-image-slice: 1

                        &:nth-child(odd)
                            & > div
                                border-image: linear-gradient(to right bottom, $border_orange 0%, $border_white 70%)
                                border-image-slice: 1

                        & > div
                            border-style: solid
                            border-width: 2px
                            padding: 20px 15px
                            height: 100%

                        .title
                            +font($font_black, $font_xs, 500, 25px)
                            margin-bottom: 15px
                            text-align: center

                        .list
                            text-align: left
                            max-width: 160px
                            margin: 0 auto
                            margin-bottom: 24px


                            li
                                &:before
                                    display: inline-block
                                    content: ""
                                    height: 5px
                                    width: 5px
                                    background: $bg_orange
                                    border-radius: 100%
                                    margin-right: 5px
                                    vertical-align: middle

                                div
                                    +font($font_gray, $font_xs, 400, 1.5)
                                    vertical-align: middle
                                    display: inline-block
                                    width: calc(100% + (-15px))

                                    p
                                        line-height: 30px
                                        display: inline-block

                                        span
                                            line-height: 30px

                                    .name
                                        width: 56px
                                        display: inline-block
                                        text-align: justify
                                        text-align-last: justify

                                        &:after
                                            content: ':'
                                            display: inline-block

                                    .price
                                        width: calc(100% + (-61px))

                                    &.original
                                        .price
                                            text-decoration: line-through
                                            text-decoration-color: $border_gray

                                    &.discount
                                        color: $font_orange

                                        .price
                                            .num
                                                font-size: $font_xs

                        .note
                            +font($font_gray, $font_xxs, 400, 16px)
                            text-align: center

                        .button
                            margin: 0 auto
                            margin-bottom: 10px

                .others
                    text-align: left
                    max-width: 870px
                    margin: 0 auto
                    margin-top: 50px

                    .others_title
                        +font($font_black, $font_s, 500, 1.2)
                        margin-bottom: 20px
                        text-align: center

                    .each
                        margin-bottom: 15px

                        .title
                            +font($font_black, $font_xs, 500, 24px)
                            margin-bottom: 5px

                            &:before
                                content: ''
                                display: inline-block
                                margin-right: 8px
                                width: 0
                                height: 0
                                border-style: solid
                                border-width: 3px 0 3px 6px
                                border-color: transparent transparent transparent $border_orange

                        .content
                            p
                                +font($font_gray, $font_xxs, 400, 22px)
                                margin-left: 14px

                                a
                                    +font($font_green, $font_xxs, 400, 22px)

                                    &:before
                                        content: ''
                                        height: 22px
                                        width: 22px
                                        display: inline-block
                                        background: url("../assets/img/search.svg") center no-repeat
                                        background-size: 11px
                                        vertical-align: top

                                    &:hover
                                        color: darken($font_green, 10%)

                                        &:before
                                            content: ''
                                            background: url("../assets/img/search_h.svg") center no-repeat
                                            background-size: 11px

                .program_info
                    text-align: center
                    +font($font_gray, $font_xs, 400, 1.5)
                    max-width: 850px
                    margin: 0 auto
                    margin-bottom: 60px

                .program_info_detail
                    margin-bottom: 40px
                    font-size: 16px
                    color: $orange
                    line-height: 28px
                    text-align: center
                    display: block

            .float_icon
                z-index: 10
                position: fixed
                right: 0px
                bottom: 50px
                +width-350
                    width: 12%

                & > a > img
                    width: 100%
                    height: auto
                    max-width: 100%

    &.error
        .main
            .detail
                padding: 80px 20px
