.container
    &.blocking_record
        .main
            .detail
                padding: 80px 20px
                max-width: 800px
                .button
                    margin: 0 auto
                    p
                        cursor: pointer
