.container
    &.faq
        .main
            .detail
                padding: 70px 20px
                max-width: 850px

                .list
                    .each
                        border: 1px solid $border_orange
                        margin-bottom: 15px

                        &:last-child
                            margin-bottom: 0

                        .question
                            padding: 5px 15px

                            .title
                                display: inline-block
                                width: calc(100% + (-30px))
                                vertical-align: top
                                +font($font_black, $font_xs, 400, 25px)

                            .btn
                                display: inline-block
                                height: 25px
                                width: 25px
                                vertical-align: top
                                background: url("../assets/img/add_o.svg") center no-repeat
                                background-size: 13px
                                cursor: pointer
                                +transition(transform 0.2s ease-in)

                        .answer
                            display: none
                            padding: 10px 15px

                            p
                                +font($font_gray, $font_xxs, 400, 20px)
                                margin-bottom: 15px

                                &:last-child
                                    margin-bottom: 0

                            a
                                color: $font_orange
                                line-height: 20px

                            img
                                max-width: 100%
                                max-height: 100%

                        &.active
                            .question
                                background: $bg_orange

                                .title
                                    color: $font_white

                                .btn
                                    background: url("../assets/img/add.svg") center no-repeat
                                    background-size: 13px
                                    +transform(rotate(45deg))
        .block_content
            margin-bottom: 20px
+width-800
    .container
        &.faq
            .main
                .detail
                    .filter
                        .item
                            margin: 0
                            &.current
                                display: flex
                                cursor: pointer
                                margin: 0 auto
                                background: $bg_orange
                                color: $font_white
                                &:after
                                    content: ' ▼'
                            &.active
                                &:after
                                    content: ' ▼'
                        ul
                            display: none
                            align-items: center
                        &.show
                            .item
                                &.current
                                    display: none
                            ul
                                display: flex
                                flex-direction: column
                                li
                                    display: flex
                                    .item
                                        background: $bg_orange
                                        color: $font_white
                                    &:first-of-type .item
                                        border-radius: 15px 15px 0 0
                                    &:not(:last-of-type):not(:first-of-type) .item
                                        border-radius: 0
                                    &:last-of-type .item
                                        border-radius: 0 0 15px 15px


