.container
    .nav
        position: fixed
        top: 0
        left: 0
        right: 0
        height: 60px
        box-shadow: 0 2px 6px 0 rgba($border_black, .2)
        z-index: 100
        background: $bg_white

        .detail
            padding: 0 20px

            & > div
                display: inline-block
                height: 60px

            .logos
                width: 250px

                .each
                    display: inline-block
                    height: 60px

                    &.cht
                        padding: 14px 5px
                        margin-right: 5px

                    &.hinet
                        padding: 13px 5px

                    img
                        max-width: 100%
                        max-height: 100%

            .user_menu
                width: calc(100% + (-255px))
                text-align: right

                .icon
                    display: none
                    height: 60px
                    width: 60px
                    background: url("../assets/img/bar.svg") center no-repeat
                    background-size: 30px
                    margin-right: 0
                    margin-left: auto
                    +box-sizing

                .menu
                    margin-right: 0
                    margin-left: auto

                    a
                        display: inline-block

                        & > div
                            height: 60px
                            text-align: center
                            padding: 0 5px

                            & > p
                                top: 50%
                                +transform(translateY(-50%))
                                padding: 5px
                                border-bottom: 2px solid transparent
                                +font($font_black, $font_xs, 400, 20px)

                            .button
                                top: 50%
                                +transform(translateY(-50%))

                            &:hover
                                & > p
                                    border-bottom: 2px solid $border_orange

                            &.active
                                & > p
                                    border-bottom: 2px solid $border_orange

                                .button.orange
                                    background: darken($bg_orange, 10%)
                                    border: 1px solid darken($border_orange, 10%)
                    .dropdown
                        display: inline-block
                        cursor: pointer
                        color: $black
                        outline: none
                        background-color: transparent
                        height: 60px
                        text-align: center
                        padding: 0 5px

                        & > p
                            top: 50%
                            +transform(translateY(-50%))
                            padding: 5px
                            +font($font_black, $font_xs, 400, 20px)
                            cursor: pointer
                            border-bottom: 2px solid transparent

                        &:hover > .dropdown-content
                            display: block

                        & > .dropdown-content
                            display: none
                            position: absolute
                            background-color: $gray
                            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
                            text-align: center
                            top: 80%

                            & > a
                                height: 30px
                                margin: 8px 12px
                                padding: 5px 0
                                text-decoration: none
                                display: block
                                border-bottom: 2px solid transparent

                                &:hover
                                    border-bottom: 2px solid $border_orange


            +width-1050
                padding: 0 20px
                .logos
                    width: 225px

                    .each
                        &.cht
                            padding: 17px 5px

                        &.hinet
                            padding: 16px 5px
                .user_menu
                    width: calc(100% + (-230px))

                    .icon
                        display: block

                        &.checked + .menu
                            display: block

                    .menu
                        position: fixed
                        width: 100%
                        max-width: 400px
                        background: $bg_white
                        display: none
                        box-shadow: 0px 2px 6px 0 rgba($border_black, 0.2)
                        border: 1px solid rgba($border_gray_light,0.5)
                        right: 0
                        a
                            display: block

                            & > div
                                padding: 0
                                height: 50px
                                & > p
                                    line-height: 38px
                                    border-color: rgba($border_gray_light,0.5)

                                .button
                                    margin: 0 auto

                                &:hover
                                    & > p
                                        background: rgba($bg_gray_light,0.5)
                                        border-color: rgba($border_gray_light,0.5)

                                &.active
                                    & > p
                                        background: rgba($bg_gray_light,0.5)
                                        border-color: rgba($border_gray_light,0.5)

                        .dropdown
                            display: block
                            padding: 0
                            height: 50px

                            & > p
                                line-height: 38px
                                border-color: rgba($border_gray_light,0.5)

                            & > .dropdown-content
                                padding: 0
                                height: 50px
                                position: relative
                                top: auto

                                & > a
                                    position: relative




