.container
    &.manager
        &.login
            .main
                min-height: calc(100vh - 205px)
                background: linear-gradient(98.852deg, $bg_light_orange 0 56%, $font_white 56% 100%)
                display: flex
                align-items: center

                .detail
                    display: flex
                    justify-content: center
                    flex-direction: row
                    align-items: center
                    flex-wrap: wrap
                    -webkit-flex-wrap: wrap
                    padding: 15px 20px

                    .img_area
                        flex: 1
                        max-width: 932px
                        height: 100%
                        margin-right: 40px

                        img
                            width: 100%
                            height: auto

                    .info
                        flex: 1
                        padding: 80px 6px
                        padding-top: 60px
                        max-width: 370px

                        .form
                            .input_wrapper
                                display: flex
                                flex-direction: column
                                gap: 3px

                                &:not(:last-child)
                                    margin-bottom: 20px

                                label
                                    +font($font_dark_green, $font_xs, 500, 30px)

                                .input_group
                                    display: flex
                                    gap: 10px 15px
                                    align-items: center
                                    flex-wrap: wrap

                                .field
                                    flex: 1
                                    display: flex
                                    align-items: center
                                    column-gap: 10px
                                    width: 100%
                                    padding: 14px 10px
                                    background: $bg_white
                                    border-radius: 10px
                                    filter: drop-shadow(1px 1px 5px rgba(#000000, 0.15))

                                    &:has(input.error)
                                        border: 2px solid $border_red
                                        padding: 12px 10px

                                    .append_inner_btn
                                        height: 24px
                                        width: 24px
                                        cursor: pointer

                                        &.open
                                            background: url('../assets/img/eye_open.svg') center no-repeat
                                            background-size: contain

                                        &.close
                                            background: url('../assets/img/eye_close.svg') center no-repeat
                                            background-size: 21.9px auto

                                    input
                                        flex: 1
                                        +font($font_black, $font_xs, 300, 24px)
                                        width: 100%
                                        border: 0px

                                        &::placeholder
                                            +font($font_gray, $font_xs, 300, 24px)

                                .captcha
                                    display: flex
                                    flex-direction: row
                                    align-items: center
                                    column-gap: 12px

                                    img
                                        flex-grow: 0
                                        flex-shrink: 0
                                        height: auto
                                        width: 130px

                                    .refresh
                                        height: 34px
                                        width: 34px
                                        background: url('../assets/img/captcha_refresh.svg') center no-repeat
                                        background-size: contain
                                        cursor: pointer
                                        transition-duration: 0.5s
                                        &:hover
                                            background: url('../assets/img/captcha_refresh_h.svg') center no-repeat
                                            background-size: contain
                                        &:active
                                            transform-origin: center
                                            transform: rotate(-180deg)
                                            transition-duration: 0s

                                & > .error
                                    +font($font_red, $font_xxs, 400, 24px)
                                    padding: 0 10px

                            .buttons
                                margin-top: 60px

                                .button
                                    width: 100%
                                    border-radius: 10px
                                    border: 1px solid currentColor

                            .others
                                display: flex
                                flex-direction: column
                                align-items: center
                                gap: 15px
                                margin-top: 20px

                                a
                                    width: fit-content

                                    p
                                        width: fit-content
                                        +font($font_dark_green, $font_xs, 400, 24px)

                                        &:hover
                                            +font(darken($font_dark_green, 10%), $font_xs, 400, 24px)

        &.account_list
            .main
                padding: 70px 20px
                .detail
                    .others_info
                        max-width: 850px
                        width: 100%
                        margin: 0 auto

                        .each
                            margin-bottom: 20px

                            .title
                                +font($font_black, $font_xs, 400, 24px)

                            .content
                                +font($font_gray, $font_xs, 400, 30px)

                                ul
                                    padding-left: 1rem

                                    li
                                        list-style-type: disc
                                        +font($font_gray, $font_xs, 400, 30px)
                                        cursor: pointer

                                        &.append_icon
                                            &:after
                                                content: ""
                                                display: inline-block
                                                height: 15px
                                                width: 15px
                                                vertical-align: middle
                                                margin-left: 0.3rem

                                            &.download
                                                &:after
                                                    background: url('../assets/img/download.svg') center no-repeat
                                                    background-size: contain

+width-1000
    .container
        &.manager
            &.login
                .main
                    background: linear-gradient(162.876deg, $bg_light_orange 0 30%, $bg_white 30% 100%)

                    .detail
                        flex-direction: column

                        .img_area
                            margin-right: 0
                            max-width: 430px

                        .info
                            padding-top: 30px
                            width: 100%

+width-400
    .container
        &.manager
            &.login
                .main
                    background: linear-gradient(162.876deg, $bg_light_orange 0 26%, $bg_white 16% 100%)

                    .detail
                        .info
                            .form
                                .input_wrapper
                                    .input_group
                                        flex-direction: column

                                    .captcha
                                        align-self: end

                                        .refresh
                                            height: 28px
                                            width: 28px
