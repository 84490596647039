@media screen and (orientation: portrait)
    .package-info li
        font-size: 20px !important

    .package-btn-group
        margin-top: 20% !important
        width: 120px
        margin: 0 auto

    .package-btn-cancel
        margin-bottom: 20px
        margin-right: 0px !important

@media screen and (max-height: 500px)
    .container.program_callback .main
        min-height: 400px

    .package-info li
        font-size: 20px !important

.package-info
    display: inline-block
    vertical-align: middle
    max-width: 850px
    padding-left: 5%
    padding-right: 5%

    .block_title
        margin-bottom: 35px !important
        text-align: left !important
        color: $black_blue

    ul
        padding: 0
        padding-left: 10%
        margin: 0
        text-align: left
        list-style-type: none

    li
        color: $dark_gray_black
        margin-bottom: 2%
        font-size: 25px
        list-style-type: square

.package-btn-group
    margin-top: 5%
    text-align: center
    display: block

.package-btn-cancel
    letter-spacing: 25px
    text-indent: 25px
    text-align: center
    width: 120px
    height: 40px
    background-color: $package_gray
    color: $font_white
    border-radius: 10px
    cursor: pointer
    border: none
    margin-right: 15px

    &:hover
        background-color: $package_dark_gray
        border: none

.package-btn-confirm
    letter-spacing: 25px
    text-indent: 25px
    text-align: center
    width: 120px
    height: 40px
    color: $font_white
    background-color: $bg_orange
    border: none
    border-radius: 10px
    cursor: pointer

    &:hover
        border: none
        background-color: $bg_dark_orange
        color: $font_dark_orange
