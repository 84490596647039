.container
    &.cybersecurity .knowledge
        background: $white
        min-height: calc(100vh + (-205px))


        .detail
            .knowledge_list .no_result
                width: 100%
                padding: 40px 0

            padding: 70px 20px
            text-align: center
            max-width: 950px

            .filter ul li
                display: inline-block

            .knowledge_list
                display: flex
                flex-wrap: wrap
                -webkit-flex-wrap: wrap

                .info
                    width: 100%
                    padding: 40px 0

    &.knowledge .post
        padding: 50px 20px
        text-align: center

        .detail
            max-width: 850px

            .block_title
                text-align: left

            .title
                text-align: left
                color: $black_blue
                font-weight: 500
                margin: 40px 0 20px

            .link
                text-align: left

                a
                    color: $font_orange
                    text-decoration: underline
                    line-height: 25px
                    vertical-align: middle

                    &::before
                        content: url('../assets/img/link_icon.png')
                        line-height: 25px
                        padding-right: 5px

            .content
                +font($dark_gray_black, $font_xs, 400, 25px)
                text-align: left
                margin: 20px 0
                a
                    line-height: 25px
                    color: $orange
                    text-decoration: underline
                    font-weight: 500

                > p
                    +font($dark_gray_black, $font_xs, 400, 25px)
                    text-align: left
                    word-wrap: break-word
                    > a
                        line-height: 25px
                        color: $orange
                        text-decoration: underline
                        font-weight: 500

            .img img
                max-width: 100%
                width: 100%
                margin-bottom: 20px

        .float_icon
            z-index: 10
            position: fixed
            right: 0px
            bottom: 50px

            a img
                width: 100%
                height: auto
                max-width: 100%

    &.cybersecurity .knowledge .detail
        .filter
            ul
                display: flex
                flex-wrap: wrap
                -webkit-flex-wrap: wrap
                justify-content: space-evenly
                width: 100%

            .item
                +font($white, $font_s, 400, 30px)
                display: inline-block
                background: $bg_green
                border-radius: 25px
                width: 170px
                min-height: 30px
                cursor: pointer
                margin: 10px

                &.current
                    display: none

                &.active, &.current
                    background: $bg_orange

        .each
            display: inline-block
            max-width: 300px
            width: 100%
            margin-top: 30px
            padding: 0 20px

            .item
                background: $light_gray
                text-align: center

                .img_area img
                    width: 260px
                    height: 205px

                .text_area
                    padding: 15px

                    .title
                        +font($black_blue, $font_s, 400,25px)
                        margin-bottom: 5px
                        text-align: left
                        overflow: hidden
                        display: -webkit-box
                        text-overflow: ellipsis
                        -webkit-line-clamp: 2
                        -webkit-box-orient: vertical
                        white-space: normal

                    .content
                        +font($dark_gray_black, $font_xs, 400, 25px)
                        text-align: left
                        overflow: hidden
                        display: -webkit-box
                        text-overflow: ellipsis
                        -webkit-line-clamp: 4
                        -webkit-box-orient: vertical
                        white-space: normal

                .btn_area .btn
                    background: $orange
                    +font($white, $font_s, 400, 30px)
                    margin: 0 15px 15px
                    display: inline-block
                    border-radius: 25px
                    min-width: 115px
                    min-height: 30px
                    cursor: pointer

        .pagination
            text-align: center
            margin-top: 20px
            width: 100%

            li
                height: 26px
                min-width: 26px
                display: inline-block
                cursor: pointer
                background-repeat: no-repeat
                background-position: center

                &:not(:last-of-type)
                    margin-right: 8px

                a
                    +font($font_black, $font_xxs, normal, 26px)
                    display: block

                &.now
                    border-bottom: 1px solid $orange

                    a
                        color: $orange

                &:hover:not(.disabled) a
                    color: $orange

                &.disabled
                    cursor: not-allowed

                &.first
                    .img
                        width: 26px
                        height: 26px
                        background: url("../assets/img/page_first.svg") right center no-repeat
                        background-size: 10px

                    &.disabled .img
                        cursor: not-allowed
                        background: url("../assets/img/page_first_d.svg") right center no-repeat
                        background-size: 10px

                    &:hover:not(.disabled) .img
                        background: url("../assets/img/page_first_w.svg") right center no-repeat
                        background-size: 10px

                &.pre
                    .img
                        width: 26px
                        height: 26px
                        background: url("../assets/img/page_pre.svg") center no-repeat
                        background-size: 10px

                    &.disabled .img
                        cursor: not-allowed
                        background: url("../assets/img/page_pre_d.svg") center no-repeat
                        background-size: 10px

                    &:hover:not(.disabled) .img
                        background: url("../assets/img/page_pre_w.svg") center no-repeat
                        background-size: 10px

                &.next
                    .img
                        width: 26px
                        height: 26px
                        background: url("../assets/img/page_next.svg") center no-repeat
                        background-size: 10px

                    &.disabled .img
                        cursor: not-allowed
                        background: url("../assets/img/page_next_d.svg") center no-repeat
                        background-size: 10px

                    &:hover:not(.disabled) .img
                        background: url("../assets/img/page_next_w.svg") center no-repeat
                        background-size: 10px

                &.last
                    .img
                        width: 26px
                        height: 26px
                        background: url("../assets/img/page_last.svg") left center no-repeat
                        background-size: 10px

                    &.disabled .img
                        cursor: not-allowed
                        background: url("../assets/img/page_last_d.svg") left center no-repeat
                        background-size: 10px

                    &:hover:not(.disabled) .img
                        background: url("../assets/img/page_last_w.svg") left center no-repeat
                        background-size: 10px

                &:first-child
                    .img
                        width: 26px
                        height: 26px
                        background: url("../assets/img/page_first.svg") right center no-repeat
                        background-size: 10px

                    &.disabled .img
                        cursor: not-allowed
                        background: url("../assets/img/page_first_d.svg") right center no-repeat
                        background-size: 10px

                    &:hover:not(.disabled) .img
                        background: url("../assets/img/page_first_w.svg") right center no-repeat
                        background-size: 10px
                &:last-child
                    .img
                        width: 26px
                        height: 26px
                        background: url("../assets/img/page_last.svg") left center no-repeat
                        background-size: 10px

                    &.disabled .img
                        cursor: not-allowed
                        background: url("../assets/img/page_last_d.svg") left center no-repeat
                        background-size: 10px

                    &:hover:not(.disabled) .img
                        background: url("../assets/img/page_last_w.svg") left center no-repeat
                        background-size: 10px


+width-800
    .container.cybersecurity .knowledge .detail
        .knowledge_list
            justify-content: center

        .filter
            .item
                margin: 0

                &.current
                    display: block
                    cursor: pointer
                    margin: 0 auto

            ul
                display: none

            &.show ul, ul li
                display: block

            &.show
                .item
                    &.current
                        display: none

                    background: $bg_orange

                    &.active::after
                        content: ' ▼'

                ul li
                    &:first-of-type .item
                        border-top-left-radius: 25px
                        border-top-right-radius: 25px
                        border-bottom-left-radius: 0
                        border-bottom-right-radius: 0

                    &:not(:last-of-type):not(:first-of-type) .item
                        border-radius: 0

                    &:last-of-type .item
                        border-top-left-radius: 0
                        border-top-right-radius: 0
                        border-bottom-left-radius: 25px
                        border-bottom-right-radius: 25px
