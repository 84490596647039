.container
    &.report
        .main
            .detail
                padding: 70px 20px

                .form
                    max-width: 600px
                    margin: 0 auto

                    .info
                        +font($font_gray, $font_xxs, 400, 20px)
                        margin-bottom: 30px

                    .input_wrapper
                        margin-bottom: 15px

                        &:last-child
                            margin-bottom: 0

                        & > label
                            display: inline-block
                            width: 76px
                            margin-right: 5px
                            +font($font_black, $font_xs, 400, 30px)

                        input
                            display: inline-block
                            height: 30px
                            width: calc(100% + (-86px))
                            padding: 5px 10px
                            cursor: text
                            border-radius: 1px
                            +font($font_black, $font_xxs, 400, 1)
                            border: 1px solid $border_black

                            &::placeholder
                                color: $font_gray
                                line-height: 20px

                            &.error
                                border: 1px solid $border_red
                        .radios
                            list-style: none
                            height: 30px
                            width: calc(100% + (-86px))
                            display: inline-block

                            li
                                display: inline-block
                                margin-right: 10px
                                +font($font_black, $font_xxs, normal, 1.2)
                                input
                                    position: absolute
                                    padding: 0
                                label
                                    padding-left: 18px
                                    padding-right: 10px
                                    line-height: 30px
                                    cursor: pointer
                                    span
                                        line-height: 30px
                                        cursor: pointer

                                    .check
                                        position: absolute
                                        display: block
                                        height: 14px
                                        width: 14px
                                        border-radius: 100%
                                        background: $bg_white
                                        border: 1px solid $border_black
                                        top: 50%
                                        +transform(translateY(-50%))
                                        cursor: pointer

                                        &:before
                                            content: ""
                                            position: absolute
                                            height: 8px
                                            width: 8px
                                            border-radius: 100%
                                            background: transparent
                                            top: 2px
                                            left: 2px

                                .radio
                                    height: 0
                                    width: 0
                                    border: 0

                                    &:checked + label .check:before
                                        background: $border_green

                        textarea
                            display: inline-block
                            height: 162px
                            width: calc(100% + (-86px))
                            padding: 5px 10px
                            cursor: text
                            border-radius: 1px
                            +font($font_black, $font_xxs, 400, 30px)
                            border: 1px solid $border_black

                            &::placeholder
                                color: $font_gray

                            &.error
                                border: 1px solid $border_red

                        .error
                            margin-left: auto
                            margin-right: 0
                            width: calc(100% + (-86px))
                            +font($font_red, $font_xxs, 400, 20px)

                        &.require
                            & > label
                                &:after
                                    display: inline-block
                                    width: 10px
                                    text-align: center
                                    content: '*'
                                    +font($font_red, $font_xs, 400, 1)

                    .captcha
                        width: calc(100% + (-86px))
                        border-radius: 1px
                        margin-left: auto
                        margin-right: 0
                        img
                            max-width: 100%
                    .buttons
                        width: calc(100% + (-86px))
                        margin-left: auto
                        margin-right: 0
                        margin-top: 15px

                        input[type="submit"], input[type="button"]
                            display: inline-block
                            height: 30px
                            min-width: 80px
                            padding: 5px 10px
                            text-align: center
                            margin-right: 10px
                            cursor: pointer
                            border-radius: 1px
                            font-size: $font_xxs
                            font-weight: 500

                            &.confirm
                                background: $border_orange
                                color: $font_white
                                border: 1px solid $border_orange

                                &:hover
                                    background: darken($bg_orange, 10%)
                                    border: 1px solid darken($border_orange, 10%)

                            &.cancel
                                background: rgba($bg_gray_dark, 0.2)
                                color: $font_gray
                                border: 1px solid rgba($border_gray, 0.2)

                                &:hover
                                    background: $bg_gray_dark
                                    color: $font_white
                                    border: 1px solid $border_gray
    &.report_result
        .main
            height: calc(100vh + (-245px))
            min-height: 200px
            .detail
                top: 50%
                +transform(translateY(-50%))
                text-align: center
                padding: 40px 20px
        .msg
            p
                display: inline-block
                +font($font_black, $font_l, normal, 40px)
                vertical-align: middle
                margin-right: 10px
            &.checking
                &:after
                    position: relative
                    content: ''
                    display: inline-block
                    vertical-align: middle
                    height: 30px
                    width: 30px
                    background: url("../assets/img/loading.gif") center no-repeat
                    background-size: contain




