*
    margin: 0
    padding: 0
    line-height: 1.2
    position: relative
    cursor: default
    vertical-align: top
    letter-spacing: 0.5px
    +box-sizing

select,
input,
textarea
    outline: none
    border: 1px solid $border_black
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

textarea
    resize: none

html
    font-size: $font_b_1920
    +width-1200
        font-size: $font_b_1200

body
    min-height: 100vh
    width: 100%
    font-family: 'Noto Sans TC', 'Microsoft JhengHei', 'PingFang', sans-serif

body::-webkit-scrollbar
    display: none

a
    text-decoration: none
    cursor: pointer
    color: $font_black
    outline: none

    *
        cursor: pointer

li
    list-style-type: none

input[type="number"]
    -moz-appearance: textfield

    &::-webkit-outer-spin-button
        -webkit-appearance: none

    &::-webkit-inner-spin-button
        -webkit-appearance: none

h1, h2, h3, h4, h5, h6
    margin: 0

input[type="checkbox"]
    display: inline-block
    width: 16px
    height: 16px
    background-color: #fff
    border: 1px solid #ccc
    border-radius: 3px
    cursor: pointer
    appearance: none

    &:checked
        background-color: #f3ae3a
        border-color: #f3ae3a
        font-size: 10px
        text-align: center
        line-height: 10px
