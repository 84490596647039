.container
    &.program
        .banner
            height: calc(100vw * 300 / 1920)
            .program_banner_img
                height: 100%
                width: auto
                display: block
            +width-540
                height: 80vw
                .program_banner_img
                    height: 100%
                    width: 100%

            .programs
                min-height: calc(100vh + (-508px))
                background: $bg_white

    &.program_detail
        .banner
            height: calc(100vw*300/1920)
            background: url("../assets/img/trial_banner.jpg") center no-repeat
            background-size: contain
            +width-540
                height: 80vw
                background: url("../assets/img/trial_banner_m.jpg") center no-repeat
                background-size: cover
        .main
            min-height: calc(100vh + (-508px))
            .detail
                padding: 70px 20px
                .index_buttons
                    display: flex
                    flex-wrap: wrap
                    -webkit-flex-wrap: wrap
                    justify-content: center
                    align-items: center
                    gap: 25px 22px

                .version_area
                    max-width: 850px
                    margin: 0 auto
                    padding-top: 60px

                    .version_title
                        text-align: center
                        margin-bottom: 30px
                        p
                            display: inline-block
                            +font($font_black, $font_l, 500, 30px)


                    .version_methods
                        display: flex
                        flex-direction: row
                        justify-content: center
                        margin-bottom: 20px
                        gap: 25px 30px
                        &.serial
                            .each
                                .content
                                    text-align: center
                        .each
                            flex: 1
                            background: $bg_light_orange
                            border-radius: 20px
                            padding: 20px 25px
                            text-align: center
                            .title

                                +font($font_darken_orange, $font_s, 500, 26px)
                                margin-bottom: 10px
                            .content
                                +font($font_darken_orange, $font_xs, 400, 22px)
                                text-align: left
                                margin-bottom: 20px
                            input[type='text']
                                background: $bg_white
                                border-radius: 8px
                                border: 1px solid $border_white
                                height: 30px
                                margin-right: 14px
                                width: 100%
                                max-width: 427px
                                padding: 0 10px
                                +font($font_black, $font_xs, 400, 28px)

                                &::placeholder
                                    text-align: center
                                    +font($font_light_gray, $font_xs, 400, 28px)


                    .version_note
                        +font($font_gray, $font_xxs, 400, 24px)
                        margin: 0 auto

            .program_title
                +font($font_black, $font_s, 500, 30px)
                margin-bottom: 5px

            .program_info
                .list
                    margin-bottom: 20px

                    li
                        +font($font_black, $font_xxs, normal, 25px)
                        list-style-type: decimal
                        margin-left: 1rem
                        +width-1200
                            font-size: $font_xs

                .eula
                    border: 1px solid $border_gray
                    padding: 15px
                    height: 350px
                    overflow-y: scroll
                    margin-bottom: 20px

                    .eula_title
                        +font($font_black, $font_xs, 500, 25px)
                        text-align: center
                        margin-bottom: 15px

                    .eula_info
                        .paragraph
                            margin-bottom: 10px
                            +font($font_black, $font_xxs, normal, 30px)

                            .p_title
                                +font($font_black, $font_xxs, 500, 25px)

                            .p_content
                                p
                                    +font($font_black, $font_xxs, normal, 25px)

                                    .important
                                        color: $font_red

                .button
                    margin: 0 auto
                    margin-bottom: 5px

                .note
                    text-align: center
                    a
                        +font($font_green, $font_xxs, normal, 25px)
                        &:hover
                            color: darken($font_green, 10%)

    &.program_callback
        .main
            height: calc(100vh + (-205px))
            min-height: 200px
            .detail
                top: 50%
                +transform(translateY(-50%))
                text-align: center
        .msg
            p
                display: inline-block
                +font($font_black, $font_l, normal, 40px)
                vertical-align: middle
                margin-right: 10px
            &.checking
                &:after
                    position: relative
                    content: ''
                    display: inline-block
                    vertical-align: middle
                    height: 30px
                    width: 30px
                    background: url("../assets/img/loading.gif") center no-repeat
                    background-size: contain


+width-650
    .container
        &.program_detail
            .main
                .detail
                    .version_area
                        .version_methods
                            flex-direction: column
                            align-items: center
                            &.serial
                                .each
                                    .content
                                        text-align: left
                            .each
                                max-width: 390px
                                input[type='text']
                                    display: block
                                    margin: 0 auto
                                    margin-bottom: 12px
                        .version_note
                            max-width: 390px
                            margin: 0 auto




+width-540
    .container
        &.program_detail
            .main
                .detail
                    .index_buttons
                        flex-direction: column

